<template>
  <div>
    <div
      v-if="alert"
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
    >
      {{ alertText }}
    </div>
    <div>
      <router-link to="/addkitchen">
        <button
          style="margin-left: 12px;"
          class="btn btn-success rounded-pill mb-2"
        >
          Add
        </button>
      </router-link>
      <button
        style="float: right; margin-left: 12px;margin-right: 12px;"
        class="btn btn-danger btn btn-danger rounded-pill mb-2"
        data-toggle="modal"
        data-target="#modalId"
        @click="confirmationBulkAction('delete')"
      >
        Delete
      </button>

      <button
        style="float: right;margin-left: 12px"
        class="btn btn-primary btn btn-primary rounded-pill mb-2"
        @click="confirmationBulkAction('deactive')"
      >
        Inactive
      </button>

      <button
        style="float: right;margin-left: 12px"
        class="btn btn-info btn btn-info rounded-pill mb-2"
        id="add-loan-btn"
        @click="confirmationBulkAction('active')"
      >
        Active
      </button>
      <div class="card-header cardHeader-bg" style="padding: 14px;">
        <h4 class="card-title mb-0">
          Restaurant Kitchen ({{ this.totalRows }})
        </h4>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
                entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
        </div>

        <div class="table-responsive mb-0">
          <b-table
            :items="loans"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            ref="selectableTable"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            show-empty
            empty-text="No record found"
            empty-filtered-text="No search result found"
          >
            <template #head(id)>
              <input v-model="isSelectAll" type="checkbox" />
            </template>
            <template v-slot:cell(id)="loans">
              <input
                type="checkbox"
                :value="loans.item.id"
                v-model="checkedNames"
              />
            </template>

            <template v-slot:cell(status)="loans">
              <i
                v-if="loans.item.status == '1'"
                style="color:green;cursor: pointer;"
                class="fas fa-check"
                @click="singlActiveDeactive(loans)"
              ></i
              >&nbsp;
              <i
                v-if="loans.item.status == '0'"
                style="color:red;cursor: pointer;"
                class="fas fa-times"
                @click="singlActiveDeactive(loans)"
              ></i>
            </template>

            <template v-slot:cell(actions)="loans">
              <i
                class="fas fa-edit"
                style="color:green;cursor: pointer;"
                @click="editData(loans)"
              ></i
              >&nbsp;
              <i
                style="color:red;cursor: pointer;"
                class="fas fa-trash-alt"
                @click="deleterRecord(loans)"
              ></i>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showKitchenDetailModal">
      <KitchenDetail
        @closeModal="closeModal"
        @ChangeKitchenCredential="ChangeKitchenCredential"
        :email="email"
        :username="username"
        :kitcheUserId="kitcheUserId"
        :restaurantId="restaurantId"
      />
    </template>
  </div>
</template>

<script>
import KitchenDetail from "./KitchenDetail";
export default {
  components: {
    KitchenDetail,
  },
  props: {
    loans: {
      type: Array,
      required: true,
    },
    updatedIsSelectAll: {
      type: Boolean,
    },
  },
  data() {
    return {
      checkedNames: [],
      isSelectAll: false,
      showKitchenDetailModal: false,
      sortDesc: true,
      alert: false,
      alertText: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "id", label: "Select", sortable: true },
        {
          key: "restaurant.name",
          label: "Restaurant Name",
          sortable: true,
        },
        { key: "email", label: "Email", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },

  mounted() {
    this.isSelectAll = this.unSelectedCheckbox;
    this.totalRows = this.loans.length;
  },

  computed: {
    rows() {
      return this.loans.length;
    },
  },

  methods: {
    confirmationBulkAction(status) {
      this.$emit("confirmationBulkAction", status, this.checkedNames);
    },

    ChangeKitchenCredential(object) {
      if (object.code == 200) {
        let index = this.loans.findIndex((x) => x.id === object.data.id);
        if (index !== -1) {
          this.loans[index].email = object.data.email;
          this.loans[index].username = object.data.username;
          this.alert = true;
          this.alertText = object.message;
          this.changeClasss = true;
        }
      } else {
        this.alert = true;
        this.alertText = object.message;
        this.changeClasss = false;
      }
    },

    closeModal(value) {
      this.isShowModel = value;
      this.showKitchenDetailModal = value;
    },
    onFiltered(filteredItems) {
      if (this.filter) {
        this.totalRows = filteredItems.length;
      } else {
        this.totalRows = this.loans.length;
      }
      this.currentPage = 1;
    },

    singlActiveDeactive(object) {
      this.$emit("showModal", true, object);
    },

    deleterRecord(object) {
      this.$emit("deleteData", object);
    },

    editData(object) {
      this.showKitchenDetailModal = true;
      this.email = object.item.email;
      this.username = object.item.username;
      this.kitcheUserId = object.item.id;
      this.restaurantId = object.item.restaurant.id;
    },

    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
  },

  watch: {
    updatedIsSelectAll(val) {
      if (val === false) {
        this.checkedNames = [];
        this.isSelectAll = false;
        this.$emit("updateStatus", true);
      }
    },
    isSelectAll: function(val) {
      if (val) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (x) => x.id
        );
      } else {
        this.checkedNames = [];
      }
    },
  },
};
</script>

<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
.btn-info {
  background-color: #697165;
  border-color: #697165;
}
.btn-info:hover {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
.btn-primary {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:hover {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:active {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:active {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-primary:focus {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:focus {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
</style>

>
